import { makeAutoObservable, runInAction } from 'mobx';
import { GroupApi } from 'service';
import { notifier } from 'utils';
export default class GroupStore {
  loading = false;
  loadingError = false;
  loadingRecordKey = '';
  groups = [];
  activePage = 1;
  total = 0;
  pageSize = 5;

  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }

  // Computed

  // Actions
  loadGroups = async () => {
    this.setLoading(true);
    const response = await GroupApi.getMany(`admin/api/expert/profile/avatar/icons`);
    if (!response.isError) {
      this.groups = response.data.payload.entries;
      this.total = response.data.payload.total;
      this.setLoadingError(false);
    } else {
      this.setLoadingError(true);
    }
    this.setLoading(false);
  };
  createGroup = async (formData) => {
    this.setLoading(true);
    const response = await GroupApi.create(
      `admin/api/expert/profile/avatar/icons/upload`,
      formData
    );
    if (response.isError) {
      notifier({
        description: response.shownMessage || 'Groups failed to create.',
        type: 'error',
      });
    } else {
      const res = await GroupApi.getOne(
        `admin/api/expert/profile/avatar/icons/${response.data.payload.id}`
      );
      if (!res.isError) {
        this.groups = [res.data.payload, ...this.groups];
      }
      notifier({ description: 'Groups successfully created.', type: 'success' });
    }
    this.setLoading(false);
  };
  updateGroup = async (id, iconKey, formData) => {
    this.setLoadingRecordKey(id);
    const response = await GroupApi.updateOne(
      `admin/api/expert/profile/avatar/icons/${iconKey}`,
      formData
    );
    if (response.isError) {
      this.setLoadingRecordKey('');
      notifier({ description: response.shownMessage || 'Groups failed to edit.', type: 'error' });
    } else {
      this.groups = this.groups.map((item) => {
        if (item.id === id) {
          return response.data.payload;
        } else {
          return item;
        }
      });
      notifier({ description: 'Groups  successfully edited.', type: 'success' });
    }
    this.setLoadingRecordKey('');
  };

  deleteGroup = async (id, iconKey) => {
    this.setLoadingRecordKey(id);
    const response = await GroupApi.deleteOne(`admin/api/expert/profile/avatar/icons/${iconKey}`);
    if (response.isError) {
      this.setLoadingRecordKey('');
      notifier({
        description: response.shownMessage || 'Groups failed to remove.',
        type: 'error',
      });
    } else {
      this.groups = this.groups.filter((item) => item.id !== id);
      notifier({ description: 'Groups successfully removed.', type: 'success' });
    }
    this.setLoadingRecordKey('');
  };
  deleteSelectedGroups = async (selectedIconKeys) => {
    this.setLoading(true);
    const promises = selectedIconKeys.map(
      (iconKey) =>
        new Promise((resolve, reject) => {
          GroupApi.deleteOne(`admin/api/expert/profile/avatar/icons/${iconKey}`).then((response) =>
            resolve(response)
          );
        })
    );
    const response = await Promise.all(promises);
    let groupError = false;
    response.forEach((response) => {
      if (response.isError) {
        groupError = true;
      }
    });
    if (groupError) {
      this.loadGroups();
      notifier({
        description: response.shownMessage || 'Groups failed to remove.',
        type: 'error',
      });
    } else {
      this.groups = this.groups.filter((item) => !selectedIconKeys.includes(item.key));
      notifier({ description: 'Groups  successfully removed.', type: 'success' });
    }
    this.setLoading(false);
  };
  setLoading = (value) => {
    this.loading = value;
  };
  setLoadingError = (value) => {
    this.loadingError = value;
  };
  setLoadingRecordKey = (key) => {
    this.loadingRecordKey = key;
  };

  setActivePage = (page) => {
    this.activePage = page;
  };

  setPageSize = (number) => {
    runInAction(() => {
      this.pageSize = number;
      this.setActivePage(1);
    });
  };
}
