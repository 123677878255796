import React from 'react';
import { observer } from 'mobx-react-lite';
import {
  CalendarOutlined,
  MailOutlined,
  SettingOutlined,
  UsergroupAddOutlined,
  TagOutlined,
  FormOutlined, ContactsOutlined, FundProjectionScreenOutlined,
} from '@ant-design/icons';
import { ROUTES } from 'utils';
import { Menu } from 'antd';
import { useDirectoryStore } from 'stores';
import { useLocation } from 'react-router-dom';

const { SubMenu } = Menu;

const SideBarMenu = ({ handleMenuTagClick }) => {
  const { tagsKeys, tagsCategories } = useDirectoryStore();
  const { pathname } = useLocation();
  return (
    <Menu defaultSelectedKeys={[pathname]} defaultOpenKeys={['settings']} mode="inline">
      <Menu.Item
        key={ROUTES.MAIN_PAGE}
        icon={<MailOutlined />}
        onClick={() => handleMenuTagClick(ROUTES.MAIN_PAGE)}
      >
        DashBoard
      </Menu.Item>
      <Menu.Item
        key={ROUTES.USERS}
        icon={<CalendarOutlined />}
        onClick={() => handleMenuTagClick(ROUTES.USERS)}
      >
        Users
      </Menu.Item>
      <SubMenu key="activity" icon={<FundProjectionScreenOutlined />} title="Activity">
        <Menu.Item
          key={ROUTES.PROJECT_APPROVAL}
          onClick={() => handleMenuTagClick(ROUTES.PROJECT_APPROVAL)}
        >
          Approval
        </Menu.Item>
        <Menu.Item
          key={ROUTES.PROJECT_SORT_ORDER}
          onClick={() => handleMenuTagClick(ROUTES.PROJECT_SORT_ORDER)}
        >
          Sort Order
        </Menu.Item>
      </SubMenu>
      <SubMenu key="events" icon={<ContactsOutlined />} title="Events">
        <Menu.Item
          key={ROUTES.ACTIVITY_APPROVAL}
          onClick={() => handleMenuTagClick(ROUTES.ACTIVITY_APPROVAL)}
        >
          Approval
        </Menu.Item>
        <Menu.Item
          key={ROUTES.ACTIVITY_SORT_ORDER}
          onClick={() => handleMenuTagClick(ROUTES.ACTIVITY_SORT_ORDER)}
        >
          Sort Order
        </Menu.Item>
      </SubMenu>
      <Menu.Item
        key={ROUTES.GROUPS}
        icon={<UsergroupAddOutlined />}
        onClick={() => handleMenuTagClick(ROUTES.GROUPS)}
      >
        Groups
      </Menu.Item>
      <Menu.Item
        key={ROUTES.LABELS}
        icon={<FormOutlined />}
        onClick={() => handleMenuTagClick(ROUTES.LABELS)}
      >
        Labels
      </Menu.Item>
      <SubMenu key="tags" icon={<TagOutlined />} title="Fields Redactor">
        {tagsKeys.map((key) => (
          <Menu.Item
            key={`${ROUTES.TAGS}/${key}`}
            onClick={() => handleMenuTagClick(`${ROUTES.TAGS}/${key}`)}
          >
            {tagsCategories[key].title}
          </Menu.Item>
        ))}
      </SubMenu>
      <SubMenu key="settings" icon={<SettingOutlined />} title="Settings">
        <Menu.Item
          key={`${ROUTES.SETTINGS_ACCESS_RIGHTS}`}
          onClick={() => handleMenuTagClick(`${ROUTES.SETTINGS_ACCESS_RIGHTS}`)}
        >
          Access rights
        </Menu.Item>
        <Menu.Item
          key={`${ROUTES.SETTINGS_ROLES}`}
          onClick={() => handleMenuTagClick(`${ROUTES.SETTINGS_ROLES}`)}
        >
          Roles
        </Menu.Item>
      </SubMenu>
    </Menu>
  );
};

export default observer(SideBarMenu);
