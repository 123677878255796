import BaseApi from 'service/baseApi';

class ProjectApi {
  client;

  constructor(client) {
    this.client = client;
  }
  getOne = async (url) => {
    return await BaseApi.get(url);
  };
  getMany = async (url) => {
    return await BaseApi.get(url);
  };
  updateProjectStatus = async (url) => {
    return await BaseApi.post(url);
  };
  updateProjectPriority = async (url, params) => {
    return await BaseApi.patch(url, params);
  };
}
export default new ProjectApi(BaseApi);
