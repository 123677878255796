import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Form, Input } from 'antd';
import { useAuthStore } from 'stores';

import styles from './Login.module.scss';
import { theme } from 'assets/styles/theme';
import { ReactComponent as Logo } from 'assets/images/logo.svg';

const Login = () => {
  const { login } = useAuthStore();

  const onFinish = (values) => {
    login(values);
  };

  return (
    <div className={styles.container}>
      <div>
        <Form
          layout="vertical"
          name="login-form"
          className={styles.loginForm}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          onFinish={onFinish}
        >
          <div className={styles.formHeader}>
            <div className={styles.logo}>
              <Logo className={styles.logoIcon} />
              <div>Bionabu admin panel</div>
            </div>
          </div>
          <Form.Item
            label="Email"
            name="username"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please input your E-mail!',
              },
            ]}
          >
            <Input className={theme.form.input} type="email" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input.Password className={theme.form.input} />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              span: 24,
            }}
          >
            <Button className={theme.form.btn} block type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default observer(Login);
