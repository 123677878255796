import React from 'react';
import ReactDOM from 'react-dom';
import 'index.scss';
import App from 'App';
import { Router } from 'react-router-dom';
import { browserHistory } from 'stores';

ReactDOM.render(
  <React.StrictMode>
    <Router history={browserHistory}>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
