import SideBar from 'layout/SideBar';
import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { useUIStore } from 'stores';
import { MenuOutlined } from '@ant-design/icons';
import UserMenu from 'layout/Header/UserMenu/UserMenu';
import styles from 'layout/Header/Header.module.scss';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { useBreakPoint, useMediaQuery } from 'hooks';

const Header = () => {
  const { setSideBarVisible } = useUIStore();
  const isXSmall = useMediaQuery(useBreakPoint().down('sm'));

  return (
    <div className={styles.header}>
      <div className={styles.btn}>
        <MenuOutlined onClick={setSideBarVisible} />
      </div>
      {!isXSmall && (
        <div className={styles.logo}>
          <Logo className={styles.logoIcon} />
        </div>
      )}
      <div className={styles.menu}>
        <UserMenu />
      </div>
    </div>
  );
};
export default observer(Header);
