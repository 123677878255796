import { makeAutoObservable } from 'mobx';
import { LikarniaApi, OptionApi } from 'service';
export default class OptionStore {
  tagOptionMap = new Map();
  labelsOptions = [];
  countriesOptions = [];
  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }

  // Computed
  // Actions
  loadOptions = async () => {
    const response = await OptionApi.getOptions(`admin/api/info/data`);
    if (!response.isError) {
      this.tagOptionMap = new Map([['industry', response.data.payload.industries],]);
      this.labelsOptions = response.data.payload.labels;
      this.countriesOptions = response.data.payload.countries;
    }
  };
}
