import BaseApi from 'service/baseApi';
import qs from 'qs';
import { notifier } from 'utils';

class AuthApi {
  client;
  constructor(client) {
    this.client = client;
  }

  login = async (data) => {
    const response = await this.client.post('admin/login', null, {
      params: data,
      paramsSerializer: (p) => qs.stringify(p),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    if (response.isError) {
      notifier({
        description: response.shownMessage || 'Failed to login something wrong.',
        type: 'error',
      });
    }
    return response;
  };

  logout = async () => {
    try {
      this.client.post('admin/logout');
    } catch (e) {
      console.error(e.message);
      return { isError: true, message: e.message };
    }
  };
  checkAuth = async () => {
    return await this.client.get('admin/api/experts/current/profiles'); // change request after api will be ready
  };
}

export default new AuthApi(BaseApi);
