import { makeAutoObservable, runInAction } from 'mobx';
import { LabelApi } from 'service';
import { notifier } from 'utils';
export default class LabelStore {
  loading = false;
  loadingError = false;
  loadingRecordKey = '';
  labels = [];
  activePage = 1;
  total = 0;
  pageSize = 5;

  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }

  // Computed

  // Actions
  loadLabels = async () => {
    this.setLoading(true);
    const response = await LabelApi.getMany(`admin/api/experts/profiles/labels`);
    if (!response.isError) {
      this.labels = response.data.payload.entries;
      this.total = response.data.payload.total;
      this.setLoadingError(false);
    } else {
      this.setLoadingError(true);
    }
    this.setLoading(false);
  };
  createLabel = async (data) => {
    console.log(data);
    this.setLoading(true);
    const response = await LabelApi.create(`admin/api/experts/profiles/labels`, data);
    if (response.isError) {
      notifier({
        description: response.shownMessage || 'Labels failed to create.',
        type: 'error',
      });
    } else {
      this.labels = [{ id: response.data.payload.id, ...data }, ...this.labels];
      notifier({ description: 'Labels successfully created.', type: 'success' });
    }
    this.setLoading(false);
  };
  updateLabel = async (id, data) => {
    console.log(data);
    this.setLoadingRecordKey(id);
    const response = await LabelApi.updateOne(`admin/api/experts/profiles/labels/${id}`, data);
    if (response.isError) {
      this.setLoadingRecordKey('');
      notifier({ description: response.shownMessage || 'Labels failed to edit.', type: 'error' });
    } else {
      this.labels = this.labels.map((item) => {
        if (item.id === id) {
          return response.data.payload;
        } else {
          return item;
        }
      });
      notifier({ description: 'Labels  successfully edited.', type: 'success' });
    }
    this.setLoadingRecordKey('');
  };

  deleteLabel = async (id) => {
    this.setLoadingRecordKey(id);
    const response = await LabelApi.deleteOne(`admin/api/experts/profiles/labels/${id}`);
    if (response.isError) {
      this.setLoadingRecordKey('');
      notifier({
        description: response.shownMessage || 'Labels failed to remove.',
        type: 'error',
      });
    } else {
      this.labels = this.labels.filter((item) => item.id !== id);
      notifier({ description: 'Labels successfully removed.', type: 'success' });
    }
    this.setLoadingRecordKey('');
  };
  deleteSelectedLabels = async (selectedIconKeys) => {
    this.setLoading(true);
    const promises = selectedIconKeys.map(
      (id) =>
        new Promise((resolve, reject) => {
          LabelApi.deleteOne(`admin/api/experts/profiles/labels/${id}`).then((response) =>
            resolve(response)
          );
        })
    );
    const response = await Promise.all(promises);
    let groupError = false;
    response.forEach((response) => {
      if (response.isError) {
        groupError = true;
      }
    });
    if (groupError) {
      this.loadLabels();
      notifier({
        description: response.shownMessage || 'Some Labels failed to remove.',
        type: 'error',
      });
    } else {
      this.labels = this.labels.filter((item) => !selectedIconKeys.includes(item.id));
      notifier({ description: 'Labels  successfully removed.', type: 'success' });
    }
    this.setLoading(false);
  };
  setLoading = (value) => {
    this.loading = value;
  };
  setLoadingError = (value) => {
    this.loadingError = value;
  };
  setLoadingRecordKey = (key) => {
    this.loadingRecordKey = key;
  };

  setActivePage = (page) => {
    this.activePage = page;
  };

  setPageSize = (number) => {
    runInAction(() => {
      this.pageSize = number;
      this.setActivePage(1);
    });
  };
}
