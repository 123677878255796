import {makeAutoObservable, runInAction} from 'mobx';
import { SettingsApi } from 'service';
import { notifier } from 'utils';
export default class SettingsStore {
  loading = false;
  loadingError = false;
  accessRights = [];
  searchedAccessRights = [];
  totalAccessRights = 0;
  roles = [];
  totalRoles = 0;
  permissions = [];
  totalPermissions = 0;
  loadingRecordKey = '';
  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }

  // Computed

  // Actions
  loadAccessRights = async () => {
    this.setLoading(true);
    const response = await SettingsApi.getMany(`admin/api/experts`);
    if (!response.isError) {
      this.accessRights = response.data.payload.entries;
      this.searchedAccessRights = response.data.payload.entries;
      this.totalAccessRights = response.data.payload.total;
      this.setLoadingError(false);
    } else {
      this.setLoadingError(true);
    }
    this.setLoading(false);
  };
  searchAccessRights = (options) => {
    this.searchedAccessRights = options.map((access) => access.item);
  };
  updateAccessRights = async (id, params) => {
    this.setLoadingRecordKey(id);
    const response = await SettingsApi.updateAccessRights(`admin/api/experts/${id}`, params);
    if (response.isError) {
      notifier({
        description: response.shownMessage || 'AccessRights failed to edit.',
        type: 'error',
      });
    } else {
      const updatedUser = response.data.payload;
      this.accessRights = this.accessRights.map((item) =>
        item.id === updatedUser.id ? updatedUser : item
      );
      this.searchedAccessRights = this.searchedAccessRights.map((item) =>
        item.id === updatedUser.id ? updatedUser : item
      );
      notifier({
        description: `AccessRights of user id: ${updatedUser.id} successfully edited`,
        type: 'success',
      });
    }
    this.setLoadingRecordKey('');
  };
  loadRoles = async () => {
    this.setLoading(true);
    const response = await SettingsApi.getMany(`admin/api/security/roles`);
    if (!response.isError) {
      this.roles = response.data.payload.entries;
      this.totalRoles = response.data.payload.total;
      this.setLoadingError(false);
    } else {
      this.setLoadingError(true);
    }
    this.setLoading(false);
  };
  updateRole = async (id, params) => {
    this.setLoadingRecordKey(id);
    const response = await SettingsApi.updateRole(`admin/api/security/roles/${id}`, params);

    if (response.isError) {
      notifier({ description: response.shownMessage || 'Role failed to edit.', type: 'error' });
    } else {
      const updatedRole = response.data.payload;
      this.roles = this.roles.map((item) => {
        if (item.id === updatedRole.id) {
          return updatedRole;
        } else {
          return item;
        }
      });
      notifier({
        description: `Role id: ${updatedRole.id} successfully edited`,
        type: 'success',
      });
    }
    this.setLoadingRecordKey('');
  };
  saveRole = async  (params) => {
    const response = await SettingsApi.createRole('admin/api/security/roles',params);
    if (!response.isError) {
      notifier({
        description: `Role  ${params.name} successfully created`,
        type: 'success',
      });
      this.loadRoles()
    } else {
      notifier({
        description: response.shownMessage || 'Failed to create role',
        type: 'error',
      });
    }
  }
  deleteRole = async (id) => {
    const response = await SettingsApi.deleteRole(`admin/api/security/roles/${id}`);
    if (!response.isError) {
      notifier({
        description: `Role  id : ${id} successfully removed`,
        type: 'success',
      });
      runInAction(() => {
        this.roles = this.roles.filter(item => item.id !==id)
      })
    } else {
      notifier({
        description: response.shownMessage || 'Failed to remove role',
        type: 'error',
      });
    }
  }
  loadPermissions = async () => {
    this.setLoading(true);
    const response = await SettingsApi.getMany(`admin/api/security/permissions`);
    if (!response.isError) {
      this.permissions = response.data.payload.entries;
      this.totalPermissions = response.data.payload.total;
      this.setLoadingError(false);
    } else {
      this.setLoadingError(true);
    }
    this.setLoading(false);
  };
  setLoading = (value) => {
    this.loading = value;
  };
  setLoadingError = (value) => {
    this.loadingError = value;
  };
  setLoadingRecordKey = (key) => {
    this.loadingRecordKey = key;
  };
}
