import axios from 'axios';
import { rootStore } from 'stores';

const client = axios.create({
  baseURL: `${window.location.origin}/`,
});

class BaseApi {
  http;

  constructor(client) {
    this.http = client;
  }

  checkRequestAuth = (error) => {
    rootStore.authStore.checkRequestAuth(!(error.response.status === 401));
  };

  get = async (url, params) => {
    try {
      return await this.http.get(url, params);
    } catch (e) {
      console.error(e.message);
      this.checkRequestAuth(e);
      return { isError: true, message: e.message, shownMessage: e.response?.data?.message };
    }
  };

  post = async (url, data, config) => {
    try {
      return await this.http.post(url, data, config);
    } catch (e) {
      console.error(e.message);
      this.checkRequestAuth(e);
      return { isError: true, message: e.message, shownMessage: e.response?.data?.message };
    }
  };

  put = async (url, params) => {
    try {
      return await this.http.put(url, params);
    } catch (e) {
      console.error(e.message);
      this.checkRequestAuth(e);
      return { isError: true, message: e.message, shownMessage: e.response?.data?.message };
    }
  };

  delete = async (url) => {
    try {
      return await this.http.delete(url);
    } catch (e) {
      console.error(e.message);
      this.checkRequestAuth(e);
      return { isError: true, message: e.message, shownMessage: e.response?.data?.message };
    }
  };

  patch = async (url, data, config) => {
    try {
      return await this.http.patch(url, data, config);
    } catch (e) {
      console.error(e.message);
      this.checkRequestAuth(e);
      return { isError: true, message: e.message, shownMessage: e.response?.data?.message };
    }
  };

  options = async (url, params) => {
    return await this.http.options(url, params);
  };
}

export default new BaseApi(client);
