import { makeAutoObservable, runInAction } from 'mobx';
import * as Api from 'service';
import { DirectoryApi } from 'service';
import { message } from 'antd';
import { notifier } from 'utils';
import { rootStore } from 'stores/index';
export default class DirectoryStore {
  loading = false;
  loadingError = false;
  loadingRecordKey = '';
  tags = [];
  searchedTags = [];
  tagsKeys = ['field_of_interest', 'area', 'role', 'industry'];
  tagsCategories = {
    field_of_interest: {
      id: 'field_of_interest',
      title: 'Field of interest & Subject matter expertise',
      subTitle: '',
    },
    area: {
      id: 'area',
      title: 'Area',
      subTitle: '',
    },
    role: {
      id: 'role',
      title: 'Role',
      subTitle: '',
    },
    industry: {
      id: 'industry',
      title: 'Industry',
      subTitle: '',
    },
  };

  activePage = 1;

  pageSize = 5;

  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }

  // Computed

  // Actions
  loadTags = async (key) => {
    this.setLoading(true);
    const response = await DirectoryApi.getMany(`admin/api/directory/${key}`);
    await rootStore.optionStore.loadOptions();
    if (!response.isError) {
      this.tags = response.data.payload.entries;
      this.searchedTags = response.data.payload.entries;
      this.total = response.data.payload.total;
      this.setLoadingError(false);
    } else {
      this.setLoadingError(true);
    }
    this.setLoading(false);
  };

  deleteTag = async (key, id) => {
    this.setLoadingRecordKey(id);
    const response = await DirectoryApi.deleteOne(`admin/api/directory/${key}/${id}`);
    if (response.isError) {
      this.setLoadingRecordKey('');
      notifier({ description: response.shownMessage || 'Tag failed to remove.', type: 'error' });
    } else {
      this.tags = this.tags.filter((item) => item.id !== id);
      this.searchedTags = this.searchedTags.filter((item) => item.id !== id);
      notifier({ description: 'Tag successfully removed.', type: 'success' });
    }
    this.setLoadingRecordKey('');
  };
  deleteSelectedTag = async (key, selectedIds) => {
    this.setLoading(true);
    const promises = selectedIds.map(
      (id) =>
        new Promise((resolve, reject) => {
          DirectoryApi.deleteOne(`admin/api/directory/${key}/${id}`).then((response) =>
            resolve(response)
          );
        })
    );
    const response = await Promise.all(promises);
    let groupError = false;
    response.forEach((response) => {
      if (response.isError) {
        groupError = true;
      }
    });
    if (groupError) {
      this.loadTags(key);
      notifier({
        description: response.shownMessage || 'Some tags failed to remove.',
        type: 'error',
      });
    } else {
      this.tags = this.tags.filter((item) => !selectedIds.includes(item.id));
      this.searchedTags = this.searchedTags.filter((item) => !selectedIds.includes(item.id));
      notifier({ description: 'Tags successfully removed.', type: 'success' });
    }
    this.setLoading(false);
  };
  updateTag = async (key, id, newValue) => {
    this.setLoadingRecordKey(id);
    const response = await DirectoryApi.updateOne(`admin/api/directory/${key}/${id}`, newValue);
    if (response.isError) {
      this.setLoadingRecordKey('');
      notifier({ description: response.shownMessage || 'Tag failed to edit.', type: 'error' });
    } else {
      this.tags = this.tags.map((item) => (item.id === id ? { id: id, ...newValue } : item));
      this.searchedTags = this.searchedTags.map((item) =>
        item.id === id ? { id: id, ...newValue } : item
      );
      notifier({ description: 'Tag successfully edited.', type: 'success' });
    }
    this.setLoadingRecordKey('');
  };

  createTag = async (key, value) => {
    this.setLoading(true);
    const response = await DirectoryApi.createOne(`admin/api/directory/${key}`, value);
    if (response.isError) {
      notifier({ description: response.shownMessage || 'Tag failed to create.', type: 'error' });
    } else {
      notifier({ description: 'Tag successfully created.', type: 'success' });
      this.tags = [{ id: response.data.payload.id, ...value }, ...this.tags];
      this.searchedTags = [{ id: response.data.payload.id, ...value }, ...this.searchedTags];
    }
    this.setLoading(false);
  };

  searchTags = (options) => {
    this.searchedTags = options.map((tag) => tag.item);
  };
  setLoading = (value) => {
    this.loading = value;
  };
  setLoadingError = (value) => {
    this.loadingError = value;
  };
  setLoadingRecordKey = (key) => {
    this.loadingRecordKey = key;
  };

  setActivePage = (page) => {
    this.activePage = page;
  };

  setPageSize = (number) => {
    runInAction(() => {
      this.pageSize = number;
      this.setActivePage(1);
    });
  };
}
