import { notification } from 'antd';
import { theme } from 'assets/styles/theme';

notification.config({
  duration: 5,
  placement: 'topRight',
  maxCount: 5,
});

const legalTypes = ['success', 'error', 'warn', 'info'];

const isNumber = (number) => {
  return typeof number === 'number' && number >= 0;
};

const messageMap = {
  success: 'Success',
  error: 'Error',
  info: 'Info',
  warn: 'Warning',
};

/**
 * Common info about notifier
 * @param message - title of notification box
 * @param description - content of notification box
 * @param type - type of notification box. Default value is 'success'
 * @param duration - time in seconds before notifier is closed. When set to 0, it will never be closed automatically
 */
export const notifier = ({ message, description, type = 'success', duration }) => {
  const legalType = legalTypes.includes(type) ? type : 'info';
  notification.open({
    message: message || messageMap[type],
    description,
    className: theme.notifier[legalType],
    duration: isNumber(duration) ? duration : 5,
  });
};
