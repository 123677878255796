export const ROUTES = {
  MAIN_PAGE: '/',
  TAGS: '/tags',
  USERS: '/users',
  POSTS: '/posts',
  LOGIN: '/login',
  GROUPS: '/groups',
  SETTINGS_ACCESS_RIGHTS: '/settings/access',
  SETTINGS_ROLES: '/settings/roles',
  LABELS: '/labels',
  PROJECT : '/activity',
  PROJECT_APPROVAL : '/activities/approval',
  PROJECT_SORT_ORDER : '/activities/sort-order',
  ACTIVITY : "/event",
  ACTIVITY_APPROVAL : '/events/approval',
  ACTIVITY_SORT_ORDER : '/events/sort-order',
};
