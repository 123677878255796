import { makeAutoObservable, runInAction } from 'mobx';
import { AuthApi } from 'service';
import { ROUTES } from 'utils';

export default class AuthStore {
  isAuth = false;
  loading = false;
  user = {};

  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }

  // Computed

  // Actions

  login = async (data) => {
    this.loading = true;
    const res = await AuthApi.login(data);
    if (res.status === 204) {
      await this.checkAuth();
      this.rootStore.router.push(ROUTES.MAIN_PAGE);
    }
    this.loading = false;
  };

  logout = async () => {
    const res = await AuthApi.logout();
    this.isAuth = false;
    this.user = {};
    this.rootStore.router.push(ROUTES.LOGIN);
  };

  checkAuth = async () => {
    this.loading = true;
    const response = await AuthApi.checkAuth();
    this.loading = false;
    if (!response.isError) {
      runInAction(() => {
        this.isAuth = true;
        this.user = response.data.payload;
      });
      return true;
    } else {
      await this.logout();
      return false;
    }
  };
  checkRequestAuth = (isAuth) => {
    if (!isAuth) {
      this.isAuth = false;
      this.user = {};
      this.logout();
    }
  };
}
