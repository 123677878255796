import { observer } from 'mobx-react-lite';

import { Avatar} from 'antd';
import { Menu, Dropdown } from 'antd';
import styles from 'layout/Header/UserMenu/UserMenu.module.scss';
import { LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { useAuthStore } from 'stores';
import { useBreakPoint, useMediaQuery } from 'hooks';
import React from 'react';
import { getAvatarUrl } from 'utils/healpers';

const MenuOverlay = () => {
  const { logout } = useAuthStore();
  return (
    <Menu className={styles.overlay}>
      {/* <Menu.Item icon={<SettingOutlined />}>
        <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
          Settings
        </a>
      </Menu.Item>
      <Menu.Item icon={<UserOutlined />}>
        <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
          Profile
        </a>
      </Menu.Item>*/}
      <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={logout}>
        Log out
      </Menu.Item>
    </Menu>
  );
};

const UserMenu = () => {
  const { user } = useAuthStore();
  const isXSmall = useMediaQuery(useBreakPoint().down('sm'));
  return (
    <Dropdown overlay={<MenuOverlay />}>
      <div className={styles.userMenu}>
        <Avatar
          icon={<UserOutlined />}
          src={<Avatar src={getAvatarUrl(user.profileImageUrl)} preview={false} icon={<UserOutlined />} />}
          style={{ width: 32, height: 32, objectFit: 'cover' }}
        />
        {!isXSmall && <div>{`${user.firstName} ${user.lastName}`}</div>}
      </div>
    </Dropdown>
  );
};

export default observer(UserMenu);
