import React from 'react';
import { createBrowserHistory } from 'history';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import UiStore from 'stores/ui.store';
import DirectoryStore from 'stores/directory.store';
import AuthStore from 'stores/auth.store';
import UserStore from 'stores/user.store';
import GroupStore from 'stores/group.store';
import SettingsStore from 'stores/settings.store';
import OptionStore from 'stores/option.store';
import LabelStore from 'stores/label.store';
import ProjectStore from "stores/project.store";
import ActivityStore from "stores/activity.store";

export const history = createBrowserHistory();

export const routingStore = new RouterStore();

export const browserHistory = syncHistoryWithStore(history, routingStore);

class RootStore {
  constructor() {
    this.router = routingStore;
    this.uiStore = new UiStore(this);
    this.directoryStore = new DirectoryStore(this);
    this.authStore = new AuthStore(this);
    this.userStore = new UserStore(this);
    this.groupStore = new GroupStore(this);
    this.settingsStore = new SettingsStore(this);
    this.optionStore = new OptionStore(this);
    this.labelStore = new LabelStore(this);
    this.projectStore = new ProjectStore(this);
    this.activityStore = new ActivityStore(this)
  }
}

export const rootStore = new RootStore();

export const StoresContext = React.createContext(rootStore);

export const useStores = () => React.useContext(StoresContext);

export const useUIStore = () => {
  const { uiStore } = useStores();
  return uiStore;
};

export const useDirectoryStore = () => {
  const { directoryStore } = useStores();
  return directoryStore;
};
export const useAuthStore = () => {
  const { authStore } = useStores();
  return authStore;
};
export const useUserStore = () => {
  const { userStore } = useStores();
  return userStore;
};
export const useGroupStore = () => {
  const { groupStore } = useStores();
  return groupStore;
};
export const useSettingsStore = () => {
  const { settingsStore } = useStores();
  return settingsStore;
};

export const useOptionStore = () => {
  const { optionStore } = useStores();
  return optionStore;
};
export const useLabelStore = () => {
  const { labelStore } = useStores();
  return labelStore;
};
export const useProjectStore = () => {
  const { projectStore } = useStores();
  return projectStore;
};
export const useActivityStore = () => {
  const { activityStore } = useStores();
  return activityStore;
};
