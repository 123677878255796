import BaseApi from 'service/baseApi';

class ActivityApi {
  client;

  constructor(client) {
    this.client = client;
  }
  getOne = async (url) => {
    return await BaseApi.get(url);
  };
  getMany = async (url) => {
    return await BaseApi.get(url);
  };
  updateActivityStatus = async (url) => {
    return await BaseApi.post(url);
  };
  updateActivityPriority = async (url, params) => {
    return await BaseApi.patch(url, params);
  };
}
export default new ActivityApi(BaseApi);
