import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from 'utils';
import Preloader from 'components/blocks/PreLoader/Preloader';
import Layout from 'layout/Layout/Layout';
import Login from 'modules/Login';

const DashBoard = lazy(() => import('modules/DashBoard'));
const Users = lazy(() => import('modules/Users'));
const Posts = lazy(() => import('modules/Posts'));
const Tags = lazy(() => import('modules/Tags'));
const SingleUser = lazy(() => import('modules/SingleUser/SingleUser'));
const Groups = lazy(() => import('modules/Groups/Groups'));
const AccessRights = lazy(() => import('modules/Settings/AccessRights/AccessRights'));
const Roles = lazy(() => import('modules/Settings/Roles/Roles'));
const Labels = lazy(() => import('modules/Labels'));
const SingleProject = lazy(() => import('modules/Projects/SingleProject/SingleProject'));
const ProjectApproval = lazy(() => import('modules/Projects/ProjectApproval/ProjectApproval'));
const ProjectSortOrder = lazy(() => import('modules/Projects/ProjectSortOrder/ProjectSortOrder'));
const SingleActivity = lazy(() => import('modules/Activities/SingleActivity/SingleActivity'));
const ActivityApproval = lazy(() => import('modules/Activities/ActivityApproval/ActivityApproval'));
const ActivitySortOrder = lazy(() => import('modules/Activities/ActivitySortOrder/ActivitySortOrder'));
const RoutesConfig = () => (
  <Layout>
    <Suspense fallback={<Preloader />}>
      <Switch>
        <Route exact path={ROUTES.MAIN_PAGE} component={DashBoard} />
        <Route exact path={ROUTES.LOGIN} component={Login} />
        <Route exact path={ROUTES.USERS} component={Users} />
        <Route exact path={`${ROUTES.USERS}/:id`} component={SingleUser} />
        <Route exact path={ROUTES.POSTS} component={Posts} />
        <Route exact path={`${ROUTES.TAGS}/:tagKey`} component={Tags} />
        <Route exact path={ROUTES.GROUPS} component={Groups} />
        <Route exact path={ROUTES.LABELS} component={Labels} />
        <Route exact path={ROUTES.SETTINGS_ACCESS_RIGHTS} component={AccessRights} />
        <Route exact path={ROUTES.SETTINGS_ROLES} component={Roles} />
        <Route exact path={`${ROUTES.PROJECT}/:id`} component={SingleProject} />
        <Route exact path={ROUTES.PROJECT_APPROVAL} component={ProjectApproval} />
        <Route exact path={ROUTES.PROJECT_SORT_ORDER} component={ProjectSortOrder} />
        <Route exact path={`${ROUTES.ACTIVITY}/:id`} component={SingleActivity} />
        <Route exact path={ROUTES.ACTIVITY_APPROVAL} component={ActivityApproval} />
        <Route exact path={ROUTES.ACTIVITY_SORT_ORDER} component={ActivitySortOrder} />

        <Redirect to={ROUTES.MAIN_PAGE} />
      </Switch>
    </Suspense>
  </Layout>
);

export default RoutesConfig;
