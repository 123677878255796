import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styles from 'components/blocks/PreLoader/Preloader.module.scss';
const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;
const Preloader = () => {
  return (
    <div className={styles.preloader}>
      <Spin indicator={antIcon} />
    </div>
  );
};
export default Preloader;
