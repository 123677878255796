import { Drawer, Layout } from 'antd';
import { observer } from 'mobx-react-lite';
import { useUIStore } from 'stores';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from 'utils/routes';
import SideBarMenu from 'layout/SideBar/SideBarMenu';
import { useBreakPoint, useMediaQuery } from 'hooks';
import { ReactComponent as Logo } from 'assets/images/logo.svg';

import styles from 'layout/SideBar/SideBar.module.scss';
import { useEffect, useState } from 'react';

const SideBar = () => {
  const { sideBarVisible, setSideBarVisible } = useUIStore();
  const isMiddle = useMediaQuery(useBreakPoint().down('lg'));
  const isXSmall = useMediaQuery(useBreakPoint().down('sm'));
  const history = useHistory();
  const SIDEBAR_WIDTH = 300;
  const handleMenuTagClick = (route) => {
    history.push(route);
    isMiddle && setSideBarVisible();
  };

  const titleContent = (
    <div className={styles.logo}>
      <Logo className={styles.logoIcon} />
    </div>
  );

  return (
    <>
      {isMiddle ? (
        <Drawer
          closable
          width={isXSmall ? '100%' : SIDEBAR_WIDTH}
          title={titleContent}
          className={styles.drawer}
          placement="left"
          onClose={setSideBarVisible}
          visible={sideBarVisible}
          key="left"
          bodyStyle={{ padding: 0 }}
        >
          <SideBarMenu handleMenuTagClick={handleMenuTagClick} />
        </Drawer>
      ) : (
        <Layout.Sider
          theme="light"
          collapsed={sideBarVisible}
          width={SIDEBAR_WIDTH}
          collapsedWidth={70}
          className={styles.sider}
        >
          <SideBarMenu handleMenuTagClick={handleMenuTagClick} />
        </Layout.Sider>
      )}
    </>
  );
};
export default observer(SideBar);
