import BaseApi from 'service/baseApi';

class OptionApi {
  client;
  constructor(client) {
    this.client = client;
  }
  getOptions = async (url) => {
    return await BaseApi.get(url);
  };
}

export default new OptionApi(BaseApi);
