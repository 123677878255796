import { makeAutoObservable } from 'mobx';

export default class UiStore {
  sideBarVisible = false;

  activeTab = '1';

  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }

  // Computed

  // Actions

  setActiveTab = (tab) => {
    this.activeTab = tab;
  };

  setSideBarVisible = () => {
    this.sideBarVisible = !this.sideBarVisible;
  };
}
