import BaseApi from 'service/baseApi';

class DirectoryApi {
  client;
  constructor(client) {
    this.client = client;
  }

  getOne = async (url) => {
    return await BaseApi.get(url);
  };

  getMany = async (url) => {
    return await BaseApi.get(url);
  };
  deleteOne = async (url) => {
    return await BaseApi.delete(url);
  };
  updateOne = async (url, params) => {
    return await BaseApi.put(url, params);
  };
  createOne = async (url, params) => {
    return await BaseApi.post(url, params);
  };
}

export default new DirectoryApi(BaseApi);
