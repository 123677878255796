import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { ConfigProvider } from 'antd';
import { useAuthStore } from 'stores';
import { Redirect, useLocation } from 'react-router-dom';
import { ROUTES } from 'utils';
import RoutesConfig from 'routes/RoutesConfig';

import 'App.scss';
import { AuthApi } from 'service';
import Preloader from 'components/blocks/PreLoader/Preloader';
import Layout from 'layout/Layout/Layout';

ConfigProvider.config({
  theme: {
    primaryColor: '#25b864',
    primary1: '#FFFFFF', //text color
    primary2: 'black', //text color
    primary3: '#FFFFFF', // bg color
  },
});

const App = () => {
  const { checkAuth, loading } = useAuthStore();
  const { pathname } = useLocation();

  useEffect(async () => {
    const auth = await checkAuth();
    if (!auth && pathname !== ROUTES.LOGIN) {
      return <Redirect to={ROUTES.LOGIN} />;
    }
    if (auth && pathname === ROUTES.LOGIN) {
      return <Redirect to={ROUTES.MAIN_PAGE} />;
    }
  }, []);

  if (loading) {
    return (
      <div className="auth-loader">
        <Preloader />
      </div>
    );
  }
  return <RoutesConfig />;
};

export default observer(App);
