import { makeAutoObservable } from 'mobx';
import {ProjectApi} from "service";
import {notifier} from "utils";
export default class ProjectStore {
  publishedProjects = [];
  projectsNeedApproval = [];
  loadingError = false;
  activeProject = null;
  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }



  // Computed


  // Actions
  loadOneProject = async (projectId) => {
    const response = await ProjectApi.getOne(`admin/api/projects/${projectId}`);
    if (!response.isError) {
      this.activeProject = response.data.payload;
      this.setLoadingError(false);
    } else {
      this.setLoadingError(true);
    }
  }
  loadProjectsNeedApproval = async () => {

    const response = await ProjectApi.getMany(`admin/api/projects/approval`);
    if (!response.isError) {
      this.projectsNeedApproval = response.data.payload.entries;
      this.setLoadingError(false);
    } else {
      this.setLoadingError(true);
    }
  }
  loadPublishedProjects = async () => {

    const response = await ProjectApi.getMany(`admin/api/projects/`);
    if (!response.isError) {
      this.publishedProjects = response.data.payload.entries;
      this.setLoadingError(false);
    } else {
      this.setLoadingError(true);
    }
  }
  updateProjectStatus = async (projectId) => {
    const response = await ProjectApi.updateProjectStatus(`admin/api/projects/${projectId}`);
    if (!response.isError) {
      notifier({ description: `New project id : ${projectId} published`, type: 'success' });
      this.projectsNeedApproval = this.projectsNeedApproval.filter(item => item.id !== projectId)
    } else {
      notifier({
        description: response.shownMessage || `Failed to publish project id : ${projectId}`,
        type: 'error',
      });
    }
  }
  updateProjectPriority = async (projectId, priority) => {
    const params = {
      value : priority
    }
    const response = await ProjectApi.updateProjectPriority(`admin/api/projects/${projectId}/priority`, params)
    if (!response.isError) {
      await this.loadPublishedProjects();
      notifier({ description: `Project id : ${projectId} priority successfully updated`, type: 'success' });
    } else {
      notifier({
        description: response.shownMessage || `Failed to update priority of project id : ${projectId}`,
        type: 'error',
      });
    }
  }
  setLoadingError = (value) => {
    this.loadingError = value;
  };
}
