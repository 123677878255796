import { makeAutoObservable, runInAction } from 'mobx';
import {UserApi} from 'service';
import { notifier } from 'utils';
export default class UserStore {
  loading = false;
  loadingError = false;
  loadingUserEditing = false;
  users = [];
  searchedUsers = [];

  currentUser = {};
  total = 0;

  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }

  // Computed
  // Actions

  loadUsers = async () => {
    this.setLoading(true);
    const response = await UserApi.getAll(`admin/api/experts/profiles`);
    if (!response.isError) {
      this.users = response.data.payload.entries;
      this.searchedUsers = response.data.payload.entries;
      this.total = response.data.payload.total;
      this.setLoadingError(false);
    } else {
      this.setLoadingError(true);
    }
    this.setLoading(false);
  };

  loadSingleUser = async (expertId) => {
    this.setLoading(true);
    const response = await UserApi.getOne(`admin/api/experts/${expertId}/profiles`);
    if (!response.isError) {
      runInAction(() => {
        this.currentUser = response.data.payload;
      });
      this.setLoadingError(false);
    } else {
      this.setLoadingError(true);
    }
    this.setLoading(false);
  };

  clearSingleUser = () => {
    runInAction(() => {
      this.currentUser = {};
    });
  };

  updateUser = async (profileId, expertId, value) => {
    if (value.avatarIconId === -1) {
      value.avatarIconId = null;
    }
    const response = await UserApi.updateOne(
      `admin/api/experts/${expertId}/profiles/${profileId}`,
      value
    );
    if (response.isError) {
      notifier({ description: response.shownMessage || 'User failed to update.', type: 'error' });
    } else {
      notifier({ description: 'User  successfully updated.', type: 'success' });
    }
    this.setLoading(false);
    runInAction(() => {
      this.currentUser = response.data.payload;
    });
  };
  deleteUser = async (expertId, profileId) => {
    this.loading=true;
    const response = await UserApi.deleteOne(`admin/api/experts/${expertId}/profiles/${profileId}`);
    this.loading=false;
    if (response.isError) {
      notifier({
        description: response.shownMessage || 'User failed to remove.',
        type: 'error',
      });
      return false;
    } else {
      runInAction(() => {
        this.loadUsers()
      })
      notifier({ description: 'User successfully removed.', type: 'success' });
      return true;
    }
  };
  searchUsers = (options) => {
    this.searchedUsers = options.map((user) => user.item);
  };
  setLoading = (value) => {
    this.loading = value;
  };

  setLoadingError = (value) => {
    this.loadingError = value;
  };

  setLoadingUserEditing = (value) => {
    this.loadingUserEditing = value;
  };
}
