export const getFullName = (a, b) => `${a} ${b}`;
export const getAvatarUrl = (uuid) => {
  if (!uuid) return '';
  return `${window.origin}/admin/api/experts/avatars/${uuid}`;
};
export const getProjectImgUrl = (uuid) => {
  if (!uuid) return '';
  return `${window.origin}/admin/api/projects/media/download/${uuid}`;
};
export const getActivityImgUrl = (uuid) => {
  if (!uuid) return '';
  return `${window.origin}/admin/api/activities/media/download/${uuid}`;
};
export const getAvatarIconUrl = (uuid) => {
  if (!uuid) return '';
  return `${window.origin}/admin/api/expert/profile/avatar/avatar/icon/${uuid}`;
};
export const showTotal = (total, range) => `${range[0]}-${range[1]} of ${total} items`;

export const autoCompleteSearch = (array, sortByField, prefix) => {
  prefix = prefix.toLowerCase();
  let result = [];
  array.forEach((item) => {
    let str = item[sortByField].toLowerCase();
    if (str.startsWith(prefix)) {
      return (result = [item, ...result]);
    }
    if (str.includes(prefix)) {
      return (result = [...result, item]);
    }
  });
  return result;
};
