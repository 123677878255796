import BaseApi from 'service/baseApi';

class SettingsApi {
  client;

  constructor(client) {
    this.client = client;
  }
  getMany = async (url) => {
    return await BaseApi.get(`${url}`);
  };
  updateAccessRights = async (url, params) => {
    return await BaseApi.put(`${url}`, params);
  };
  updateRole = async (url, params) => {
    return await BaseApi.put(`${url}`, params);
  };
  createRole = async (url, params) => {
    return await BaseApi.post(`${url}`, params);
  };
  deleteRole = async (url, params) => {
    return await BaseApi.delete(`${url}`);
  };
}
export default new SettingsApi(BaseApi);
