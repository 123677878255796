import HeaderContent from 'layout/Header/Header';
import FooterContent from 'layout/Footer/Footer';
import { Layout as LayoutAntd } from 'antd';
import { ROUTES } from 'utils';
import { useLocation } from 'react-router-dom';
import React from 'react';
import { observer } from 'mobx-react-lite';

import styles from 'layout/Layout/Layout.module.scss';
import SideBar from 'layout/SideBar';

const { Header, Content, Footer } = LayoutAntd;

const Layout = ({ children }) => {
  const { pathname } = useLocation();

  return (
    <div className={styles.layout}>
      <Header className={styles.header}>
        <HeaderContent />
      </Header>
      <LayoutAntd>
        {pathname === ROUTES.LOGIN ? null : <SideBar />}
        <Content className={styles.content}>{children}</Content>
      </LayoutAntd>
      <Footer className={styles.footer}>
        <FooterContent />
      </Footer>
    </div>
  );
};

export default observer(Layout);
