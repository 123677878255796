import BaseApi from 'service/baseApi';

class UserApi {
  client;
  constructor(client) {
    this.client = client;
  }
  getOne = async (url) => {
    return await BaseApi.get(url);
  };
  getAll = async (url) => {
    return await BaseApi.get(url);
  };
  updateOne = async (url, params) => {
    return await BaseApi.put(url, params);
  };
  deleteOne = async (url, config) => {
    return await BaseApi.delete(url, config);
  };
}

export default new UserApi(BaseApi);
