import BaseApi from 'service/baseApi';

class LabelApi {
  client;

  constructor(client) {
    this.client = client;
  }
  getOne = async (url) => {
    return await BaseApi.get(url);
  };
  getMany = async (url) => {
    return await BaseApi.get(url);
  };
  updateOne = async (url, params) => {
    return await BaseApi.put(url, params);
  };
  deleteOne = async (url) => {
    return await BaseApi.delete(url);
  };
  create = async (url, params) => {
    return await BaseApi.post(url, params);
  };
}
export default new LabelApi(BaseApi);
