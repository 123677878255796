import { makeAutoObservable } from 'mobx';
import {ActivityApi } from "service";
import {notifier} from "utils";
export default class ActivityStore {
  publishedActivities = [];
  activitiesNeedApproval = [];
  loadingError = false;
  activeActivity = null;
  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }



  // Computed


  // Actions

  loadOneActivity = async (activityId) => {
    const response = await ActivityApi.getOne(`admin/api/activities/${activityId}`);
    if (!response.isError) {
      this.activeActivity = response.data.payload;
      this.setLoadingError(false);
    } else {
      this.setLoadingError(true);
    }
  }
  loadActivitiesNeedApproval = async () => {

    const response = await ActivityApi.getMany(`admin/api/activities/approval`);
    if (!response.isError) {
      this.activitiesNeedApproval = response.data.payload.entries;
      this.setLoadingError(false);
    } else {
      this.setLoadingError(true);
    }
  }
  loadPublishedActivities = async () => {

    const response = await ActivityApi.getMany(`admin/api/activities`);
    if (!response.isError) {
      this.publishedActivities = response.data.payload.entries;
      this.setLoadingError(false);
    } else {
      this.setLoadingError(true);
    }
  }
  setLoadingError = (value) => {
    this.loadingError = value;
  };
  updateActivityStatus = async (activityId) => {
    const response = await ActivityApi.updateActivityStatus(`admin/api/activities/${activityId}`);
    if (!response.isError) {
      notifier({ description: `New activity id : ${activityId} published`, type: 'success' });
      this.activitiesNeedApproval = this.activitiesNeedApproval.filter(item => item.id !== activityId)
    } else {
      notifier({
        description: response.shownMessage || `Failed to publish activity id : ${activityId}`,
        type: 'error',
      });
    }
  }
  updateActivityPriority = async (activityId, priority) => {
    const params = {
      value : priority
    }
    const response = await ActivityApi.updateActivityPriority(`admin/api/activities/${activityId}/priority`, params)
    if (!response.isError) {
      await this.loadPublishedActivities();
      notifier({ description: `Activity id : ${activityId} priority successfully updated`, type: 'success' });
    } else {
      notifier({
        description: response.shownMessage || `Failed to update priority of activity id : ${activityId}`,
        type: 'error',
      });
    }
  }
}
